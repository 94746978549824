import { Flex, Spinner } from "@chakra-ui/react";

const Loader = ({ size = "xl", thickness = "4px", m = 10 }) => {
  return (
    <Flex
      // h={"calc(100vh - 90px)"}
      margin={"auto"}
      justifyContent={"center"}
      flexDir={"column"}
      alignItems={"center"}
    >
      <Spinner
        thickness={thickness}
        speed="0.65s"
        emptyColor="gray.200"
        color={"var(--astrology-nav)"}
        size={size}
        m={m}
      />
    </Flex>
  );
};

export default Loader;
