import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderColor: "#68030D", // change the border color
    _checked: {
      borderColor: "#68030D",
      background: "#FFF",
      _before: {
        color: "#68030D",
        background: "#68030D",
      },
      _hover: { background: "none", borderColor: "none" },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
