import { Button } from "@chakra-ui/react";
import React from "react";

const CustomButton = (props) => {
  let btnId = props?.id
    ? props.id
    : props?.title && typeof props?.title === "string"
    ? props?.title
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()
        .replace(/[^a-zA-Z- ]/g, "")
    : props?.name && typeof props?.name === "string"
    ? props?.name
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()
        .replace(/[^a-zA-Z- ]/g, "")
    : "";
  return (
    <Button id={btnId} height={"36px"} {...props} _hover={props.hover ?? {}}>
      {props.name || props.title}
    </Button>
  );
};

export default CustomButton;
