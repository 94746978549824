// App.js
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import ProtectedRoute from "./routing/ProtectedRoute";
import { authRoutes } from "./routing/routes";

function getRoutes(routes) {
  return routes.map((route) => {
    if (route.path && !route.directLink) {
      return (
        <Route key={route.path} path={route.path} element={route.component} />
      );
    }
    if (route.parentRoute) {
      return (
        <Route key={route.name} element={route.component}>
          {getRoutes(route.subComponents)}
        </Route>
      );
    }
    return null;
  });
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <main className="container content">
        <Routes>
          <Route element={<ProtectedRoute routes={authRoutes} />}>
            {getRoutes(authRoutes)}
          </Route>
        </Routes>
      </main>
    </Router>
  );
}
export default App;
